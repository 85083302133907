import React, { Fragment } from 'react';
import './travel.css';


import TextParagraph from '../../components/descriptions/TextParagraph';
import GoHere from '../../components/descriptions/GoHere';
import ForReservation from '../../components/descriptions/ForReservation';
import TextEmailParagraph from '../../components/descriptions/TextEmailParagraph';

const Travel = () => {
    document.title = "Travel";
    return (
        <Fragment>
            <div className="travel-aside d-none d-md-block">

            </div>
            <div className="travel-plant-right d-none d-md-block">

            </div>

            <div className="d-md-none faqs-text">
                {/* design mobile */}
                <div className="main-mobile-description">
                    <TextParagraph
                        estilo='description-mobile-title'
                        descriptions='GETTING THERE:'
                    />
                    <TextParagraph
                        estilo='text-mobile-description'
                        descriptions='Guests should book their flights to Providenciales, Turks and Caicos.'
                    />
                    <TextParagraph
                        estilo='text-mobile-description'
                        descriptions='Complimentary Charter Flight swill be available:'
                    />
                    <TextParagraph
                        estilo='text-mobile-description pl-3 '
                        descriptions=' - From Providenciales to South Caicos (Thursday, November 26th and Friday, November 27th)'
                    />
                    <TextParagraph
                        estilo='text-mobile-description pl-3 '
                        descriptions=' - From South Caicos to Providenciales (Sunday, November 29th and Monday, November 30th)'
                    />

                    <TextParagraph
                        estilo='text-mobile-description mt-3'
                        descriptions='Covid19 Update: To enter Turks and Caicos, international travelers are required to provide:'
                    />
                    <TextParagraph
                        estilo='text-mobile-description pl-3 mt-2'
                        descriptions='1) A negative Covid19 PCR Test (the test must be taken within 5 days prior to travelling). '
                    />
                    <TextParagraph
                        estilo='text-mobile-description pl-3 mt-2'
                        descriptions='2) Insurance which covers Covid19 medical costs.'
                    />
                    <TextParagraph
                        estilo='text-mobile-description pl-3 mt-2'
                        descriptions='3) Completed online health screening questionnaire. '
                    />

                    <TextParagraph
                        estilo='text-mobile-description mt-2'
                        descriptions='** Please be advised, Venezuelans with only Venezuelan passport require a US VISA to enter Turks and Caicos Islands.'
                    />
                    <TextEmailParagraph
                        estilo="text-mobile-description mt-2"
                        descriptions="For more information and planning please feel free to contact us at "
                        emailTo="mailto:kervelstipa@gmail.com"
                        redireccionamiento="kervelstipa@gmail.com"
                    />
                    <TextParagraph
                        estilo='description-mobile-title mt-3'
                        descriptions='ACCOMMODATIONS:'
                    />
                    <TextParagraph
                        estilo='text-mobile-description'
                        descriptions='Guests should book hotels directly from the following list: '
                    />
                    {/* ******************** */}
                    <TextParagraph
                        estilo='text-mobile-description'
                        descriptions='Sailrock Resort'
                    />
                    <TextEmailParagraph
                        estilo="text-mobile-description"
                        emailTo="http://www.sailrockresort.com"
                        redireccionamiento="sailrockresort.com"
                    />
                    <TextEmailParagraph
                        estilo="text-mobile-description"
                        descriptions="For rates please contact "
                        emailTo="mailto:ldaniel@sailrockresort.com"
                        redireccionamiento="ldaniel@sailrockresort.com"
                        endDescriptions=""
                    />
                    <TextParagraph
                        estilo='text-mobile-description'
                        descriptions='* Includes 15% discount in F&B'
                    />
                    {/* <TextParagraph
                        estilo='text-mobile-description'
                        descriptions='Booking Code: stipagroup'
                    /> */}
                    {/* ********************* */}

                    {/* ******************* */}
                    <TextParagraph
                        estilo='text-mobile-description mt-3'
                        descriptions='East Bay Resort'
                    />
                    <TextEmailParagraph
                        estilo="text-mobile-description"
                        emailTo="http://www.eastbayresort.com"
                        redireccionamiento="eastbayresort.com"
                    />
                    <TextParagraph
                        estilo='text-mobile-description'
                        descriptions='Booking Code: Stipagroup'
                    />
                    <TextEmailParagraph
                        estilo="text-mobile-description mb-5"
                        descriptions="Or contact "
                        emailTo="mailto:reservations@eastbayresort.com"
                        redireccionamiento="reservations@eastbayresort.com"
                        endDescriptions=""
                    />
                    {/* <TextParagraph
                        estilo='text-mobile-description'
                        descriptions='South Caicos Ocean and Beach Resort'
                    />
                    <TextEmailParagraph
                        estilo="text-mobile-description mb-5"
                        emailTo="http://www.oceanandbeachresort.com"
                        redireccionamiento="oceanandbeachresort.com"
                    /> */}
                    {/* <TextParagraph
                            estilo='description-mobile-title'
                            descriptions='GETTING THERE:'
                        />
                        <TextParagraph
                            estilo='text-mobile-description'
                            descriptions='Please be advised the border Venezuela-Aruba is currently closed.'
                        />
                        <TextParagraph
                            estilo='text-mobile-shedule-description mt-2'
                            descriptions='Short-Stay Dutch Caribbean Visa is required for all Venezuelans who do not hold a US VISA or other passport.'
                        />

                        <GoHere
                            estilo='text-mobile-description'
                            descriptions='Venezolanos sin Visa americana u otro pasaporte tendrán que solicitar una Visa de Corta Duración para el Caribe Neerlandés.'
                            toGo='https://www.paisesbajosytu.nl/su-pais-y-los-paises-bajos/venezuela/viajes-y-residencia/solicitar-un-visado-para-estancias-de-corta-duracion-en-el-caribe-holandes'
                            textToGo='For more information click here'
                        />

                        <TextParagraph
                            estilo='text-mobile-shedule-description mt-2'
                            descriptions='Any updates will be announced.'
                        />

                        <GoHere
                            estilo='text-mobile-description'
                            descriptions='Yellow Book with the Yellow Fever Vaccine will be requested for all passengers coming from South and Central American Countries and African Countries.'
                            toGo='https://www.airportaruba.com/yellow-fever'
                            textToGo='For more information click here'
                        />

                        <TextParagraph
                            estilo='description-mobile-title mt-4'
                            descriptions='ACCOMMODATIONS:'
                        />

                        <TextParagraph
                            estilo='text-mobile-shedule-description mt-2'
                            descriptions='The Ritz-Carlton, Aruba'
                        />

                        <TextParagraph
                            estilo='text-mobile-shedule-description'
                            descriptions='Rate: $299 (excluding taxes) with a minimum 2-day stay'
                        />

                        <ForReservation
                            estilo='text-mobile-shedule-description'
                            descriptions='For reservations'
                            toGo='https://book.passkey.com/go/stipakervel'
                            textToGo='click here'
                        />


                        <GoHere
                            estilo='text-mobile-description mt-2'
                            descriptions='Or visit the Marriott Website for other options'
                            toGo='https://www.marriott.com/search/findHotels.mi'
                            textToGo='Click here.'
                        />

                        <TextParagraph
                            estilo='text-mobile-description'
                            descriptions='Additional options may be found on other booking sites.'
                        />*/}
                    {/* End design mobile */}
                </div>
            </div>


            <div className="shedule d-none d-md-block">
                {/* Design pc */}
                <TextParagraph
                    estilo='description-text-title mt-3'
                    descriptions='GETTING THERE:'
                />
                <TextParagraph
                    estilo='text-description-shedule mt-2'
                    descriptions='Guests should book their flights to Providenciales, Turks and Caicos.'
                />
                <TextParagraph
                    estilo='text-description-shedule mt-2'
                    descriptions='Complimentary Charter Flights will be available:'
                />
                <TextParagraph
                    estilo='text-description-shedule pl-3 mt-1'
                    descriptions=' - From Providenciales to South Caicos (Thursday, November 26th and Friday, November 27th)'
                />
                <TextParagraph
                    estilo='text-description-shedule pl-3 mt-1'
                    descriptions=' - From South Caicos to Providenciales (Sunday, November 29th and Monday, November 30th)'
                />

                <TextParagraph
                    estilo='text-description-shedule mt-3'
                    descriptions='Covid19 Update: To enter Turks and Caicos, international travelers are required to provide:'
                />
                <TextParagraph
                    estilo='text-description-shedule pl-3 mt-2'
                    descriptions='1) A negative Covid19 PCR Test (the test must be taken within 5 days prior to travelling). '
                />
                <TextParagraph
                    estilo='text-description-shedule pl-3 mt-2'
                    descriptions='2) Insurance which covers Covid19 medical costs.'
                />
                <TextParagraph
                    estilo='text-description-shedule pl-3 mt-2'
                    descriptions='3) Completed online health screening questionnaire. '
                />

                <TextParagraph
                    estilo='text-description-shedule mt-3'
                    descriptions='** Please be advised, Venezuelans with only Venezuelan passport require a US VISA to enter Turks and Caicos Islands.'
                />
                <TextEmailParagraph
                    estilo="text-description-shedule mt-2"
                    descriptions="For more information and planning please feel free to contact us at "
                    emailTo="mailto:kervelstipa@gmail.com"
                    redireccionamiento="kervelstipa@gmail.com"
                />
                <TextParagraph
                    estilo='description-text-title mt-3'
                    descriptions='ACCOMMODATIONS:'
                />
                <TextParagraph
                    estilo='text-description-shedule mt-2'
                    descriptions='Guests should book hotels directly from the following list: '
                />
                {/* ******************** */}
                <TextParagraph
                        estilo='text-description-shedule mt-3'
                        descriptions='Sailrock Resort'
                    />
                    <TextEmailParagraph
                        estilo="text-description-shedule"
                        emailTo="http://www.sailrockresort.com"
                        redireccionamiento="sailrockresort.com"
                    />
                    <TextEmailParagraph
                        estilo="text-description-shedule"
                        descriptions="For rates please contact "
                        emailTo="mailto:ldaniel@sailrockresort.com"
                        redireccionamiento="ldaniel@sailrockresort.com"
                        endDescriptions=""
                    />
                    <TextParagraph
                        estilo='text-description-shedule'
                        descriptions='* Includes 15% discount in F&B'
                    />
                    {/* <TextParagraph
                        estilo='text-mobile-description'
                        descriptions='Booking Code: stipagroup'
                    /> */}
                    {/* ********************* */}

                    {/* ******************* */}
                    <TextParagraph
                        estilo='text-description-shedule mt-3'
                        descriptions='East Bay Resort'
                    />
                    <TextEmailParagraph
                        estilo="text-description-shedule"
                        emailTo="http://www.eastbayresort.com"
                        redireccionamiento="eastbayresort.com"
                    />
                    <TextParagraph
                        estilo='text-description-shedule'
                        descriptions='Booking Code: Stipagroup'
                    />
                    <TextEmailParagraph
                        estilo="text-description-shedule mb-5"
                        descriptions="Or contact "
                        emailTo="mailto:reservations@eastbayresort.com"
                        redireccionamiento="reservations@eastbayresort.com"
                        endDescriptions=""
                    />
                {/* <div className="">
                    <TextParagraph
                        estilo='description-text-title'
                        descriptions='GETTING THERE:'
                    />
                    <TextParagraph
                        estilo='text-description'
                        descriptions='Please be advised the border Venezuela-Aruba is currently closed.'
                    />
                    <TextParagraph
                        estilo='text-description mt-2'
                        descriptions='Short-Stay Dutch Caribbean Visa is required for all Venezuelans who do not hold a US VISA or other passport.'
                    />

                    <GoHere
                        estilo='text-description'
                        descriptions='Venezolanos sin Visa americana u otro pasaporte tendrán que solicitar una Visa de Corta Duración para el Caribe Neerlandés.'
                        toGo='https://www.paisesbajosytu.nl/su-pais-y-los-paises-bajos/venezuela/viajes-y-residencia/solicitar-un-visado-para-estancias-de-corta-duracion-en-el-caribe-holandes'
                        textToGo='For more information click here'
                    />

                    <TextParagraph
                        estilo='text-description mt-2'
                        descriptions='Any updates will be announced.'
                    />

                    <GoHere
                        estilo='text-description'
                        descriptions='Yellow Book with the Yellow Fever Vaccine will be requested for all passengers coming from South and Central American Countries and African Countries.'
                        toGo='https://www.airportaruba.com/yellow-fever'
                        textToGo='For more information click here'
                    />

                    <TextParagraph
                        estilo='description-text-title mt-4'
                        descriptions='ACCOMMODATIONS:'
                    />

                    <TextParagraph
                        estilo='text-description-shedule mt-2'
                        descriptions='The Ritz-Carlton, Aruba'
                    />

                    <TextParagraph
                        estilo='text-description-shedule'
                        descriptions='Rate: $299 (excluding taxes) with a minimum 2-day stay'
                    />

                    <ForReservation
                        estilo='text-description-shedule'
                        descriptions='For reservations'
                        toGo='https://book.passkey.com/go/stipakervel'
                        textToGo='click here'
                    />


                    <GoHere
                        estilo='text-description-shedule mt-2'
                        descriptions='Or visit the Marriott Website for other options'
                        toGo='https://www.marriott.com/search/findHotels.mi'
                        textToGo='Click here.'
                    />

                    <TextParagraph
                        estilo='text-description mt-2'
                        descriptions='Additional options may be found on other booking sites.'
                    />
                </div> */}
                {/* End design */}
            </div>
        </Fragment>
    );
};

export default Travel;