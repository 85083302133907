import React, { Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Navigation from './components/navbar/Navigation';

function App() {
  return (
    <Fragment>
      <Router>
        <Navigation />
      </Router>
    </Fragment>

  );
}

export default App;
