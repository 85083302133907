import React from 'react';
import './textParagraph.css';


function Boton({ textValue, LinkGo, styles, estiloButton }) {
    return (
        <div className={estiloButton}>
            
                <a
                    href={LinkGo}
                    target='_blank'
                    rel="noopener noreferrer"
                    className={styles}
                >
                    {textValue}
                </a>
            
        </div>

    );
}

export default Boton;