import React, { Fragment } from 'react';
import './textParagraph.css';

function TextParagraph({estilo, descriptions}) {
    return (
        <Fragment>
            <p className={estilo}>{descriptions}</p>
        </Fragment>
    );
}

export default TextParagraph;