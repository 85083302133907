import React, { Fragment } from 'react';

function TextEmailParagraph({ estilo, descriptions, redireccionamiento, phoneNumber, callNumber, emailTo, endDescriptions }) {
    return (
        <Fragment>
            <p className={estilo}>{descriptions}<a href={emailTo} target="_blank" rel="noopener noreferrer">{redireccionamiento}</a>{endDescriptions}</p>
        </Fragment>
    );
}

export default TextEmailParagraph;