import React, { Fragment } from 'react';
import './shedule.css';
import '../../components/text-description/text.css';

import TextWelcome from '../../components/text-description/TextWelcome';
import TextParagraph from '../../components/descriptions/TextParagraph';

const Shedule = () => {
    document.title = "Shedule";
    return (
        <Fragment>
            <div className="shedule-aside d-none d-md-block">

            </div>
            <div className="shedule-plant-right d-none d-md-block">

            </div>
            {/* desig mobile */}
            <div className="d-md-none shedule-text">
                <div className="">
                    <div className="main-mobile-description">
                        <TextParagraph
                            estilo='description-mobile-title'
                            descriptions='BEACH PARTY:'
                        />

                        <TextParagraph
                            estilo='text-mobile-shedule-description mt-1'
                            descriptions='Sailrock Resort'
                        />
                        <TextParagraph
                            estilo='text-mobile-shedule-description'
                            descriptions='2.00 pm'
                        />
                        <TextParagraph
                            estilo='text-mobile-shedule-description'
                            descriptions='Friday, November 27th, 2020'
                        />
                        <TextParagraph
                            estilo='description-mobile-title mt-4'
                            descriptions='CEREMONY:'
                        />
                        <TextParagraph
                            estilo='text-mobile-shedule-description mt-1'
                            descriptions='St. Lucy’s Chapel'
                        />
                        <TextParagraph
                            estilo='text-mobile-shedule-description'
                            descriptions='4.00pm'
                        />
                        <TextParagraph
                            estilo='text-mobile-shedule-description'
                            descriptions='Saturday, November28th, 2020'
                        />
                        <TextParagraph
                            estilo='text-mobile-shedule-description'
                            descriptions='Dresscode: Beach Formal'
                        />
                        <TextParagraph
                            estilo='description-mobile-title mt-4' 
                            descriptions='RECEPTION:'
                        />
                        <TextParagraph
                            estilo='text-mobile-shedule-description mt-1'
                            descriptions='Sailrock Resort-Cove Restaurant'
                        />
                        <TextParagraph
                            estilo='text-mobile-shedule-description'
                            descriptions='6.00 pm'
                        />
                        <TextParagraph
                            estilo='text-mobile-shedule-description'
                            descriptions='Saturday, November 28th, 2020'
                        />
                        <TextParagraph
                            estilo='text-mobile-shedule-description'
                            descriptions='Dresscode: Beach Formal'
                        />
                        {/* <TextParagraph
                            estilo='description-mobile-title'
                            descriptions='CEREMONY:'
                        />

                        <TextParagraph
                            estilo='text-mobile-shedule-description mt-1'
                            descriptions='Alto Vista Chapel'
                        />

                        <TextParagraph
                            estilo='text-mobile-shedule-description'
                            descriptions='5.30 pm'
                        />

                        <TextParagraph
                            estilo='text-mobile-shedule-description'
                            descriptions='Saturday, June 13, 2020'
                        />

                        <TextParagraph
                            estilo='text-mobile-shedule-description mt-3'
                            descriptions='Shuttles will be available from The Ritz-Carlton, Aruba'
                        />

                        <TextParagraph
                            estilo='text-mobile-shedule-description'
                            descriptions='(Time of departure to be confirmed)'
                        />

                        <TextParagraph
                            estilo='description-mobile-title mt-4'
                            descriptions='RECEPTION:'
                        />

                        <TextParagraph
                            estilo='text-mobile-shedule-description mt-1'
                            descriptions='The Ritz-Carlton, Aruba Ballroom'
                        />

                        <TextParagraph
                            estilo='text-mobile-shedule-description'
                            descriptions='7.30 pm'
                        />

                        <TextParagraph
                            estilo='text-mobile-shedule-description'
                            descriptions='Saturday, June 13, 2020.'
                        />

                        <TextParagraph
                            estilo='text-mobile-shedule-description'
                            descriptions='Dresscode: Blacktie'
                        /> */}
                    </div>
                </div>
            </div>
            {/* end design mobile */}

            {/* design pc */}
            <div className="shedule d-none d-md-block">
                <TextParagraph
                    estilo='description-text-title'
                    descriptions='BEACH PARTY:'
                />

                <TextParagraph
                    estilo='text-description-shedule mt-2'
                    descriptions='Sailrock Resort'
                />
                <TextParagraph
                    estilo='text-description-shedule'
                    descriptions='2.00 pm'
                />
                <TextParagraph
                    estilo='text-description-shedule'
                    descriptions='Friday, November 27th, 2020'
                />
                <TextParagraph
                    estilo='description-text-title mt-3'
                    descriptions='CEREMONY:'
                />
                <TextParagraph
                    estilo='text-description-shedule'
                    descriptions='St. Lucy’s Chapel'
                />
                <TextParagraph
                    estilo='text-description-shedule'
                    descriptions='4.00pm'
                />
                <TextParagraph
                    estilo='text-description-shedule'
                    descriptions='Saturday, November 28th, 2020'
                />
                <TextParagraph
                    estilo='text-description-shedule'
                    descriptions='Dresscode: Beach Formal'
                />
                <TextParagraph
                    estilo='description-text-title mt-3'
                    descriptions='RECEPTION:'
                />
                <TextParagraph
                    estilo='text-description-shedule'
                    descriptions='Sailrock Resort-Cove Restaurant'
                />
                <TextParagraph
                    estilo='text-description-shedule'
                    descriptions='6.00 pm'
                />
                <TextParagraph
                    estilo='text-description-shedule'
                    descriptions='Saturday, November 28th, 2020'
                />
                <TextParagraph
                    estilo='text-description-shedule'
                    descriptions='Dresscode: Beach Formal'
                />
                {/*  
 
                <TextParagraph
                    estilo='text-description-shedule'
                    descriptions='5.30 pm'
                />

                <TextParagraph
                    estilo='text-description-shedule'
                    descriptions='Saturday, June 13, 2020.'
                />

                <TextParagraph
                    estilo='text-description-shedule mt-3'
                    descriptions='Shuttles will be available from The Ritz-Carlton, Aruba'
                />

                <TextParagraph
                    estilo='text-description-shedule'
                    descriptions='(Time of departure to be confirmed)'
                />

                <TextParagraph
                    estilo='description-text-title mt-3'
                    descriptions='RECEPTION:'
                />

                <TextParagraph
                    estilo='text-description-shedule mt-1'
                    descriptions='The Ritz-Carlton, Aruba Ballroom'
                />

                <TextParagraph
                    estilo='text-description-shedule'
                    descriptions='7.30 pm'
                />

                <TextParagraph
                    estilo='text-description-shedule'
                    descriptions='Saturday, June 13, 2020.'
                />

                <TextParagraph
                    estilo='text-description-shedule'
                    descriptions='Dresscode: Blacktie'
                /> */}
            </div>


        </Fragment>
    );
};

export default Shedule;