import React, { Fragment } from 'react';

import ELISA_DANIEL from '../../images/elisadaniel.png';
import './home.css';

function Home() {
    document.title="Home";
    return (
        <Fragment>
            <div className="home-background-uno">
                <div className="home-elisa-daniel d-md-none">
                    <img src={ELISA_DANIEL} alt="" />
                </div>
                <div className="home-elisa-daniel-large d-none d-md-block">
                    <img src={ELISA_DANIEL} alt="" />
                </div>
            </div>
        </Fragment>
    );
}

export default Home;