import React, { Fragment } from "react";
import { Switch, Route } from 'react-router-dom';

import Menu from './Menu';
import Home from '../../views/home/Home';
import Shedule from '../../views/shedule/Shedule';
import Travel from '../../views/travel/Travel';
import Things from '../../views/things/ThingsToDo';
import Faqs from '../../views/faqs/Faqs';
import Registry from '../../views/registry/Registry';

function Navigation(props) {
    return (
        <Fragment>
            <Menu />
            <Switch>

                <Route exact path='/' component={Home} />
                <Route exact path='/schedule' component={Shedule} />
                <Route exact path='/travel' component={Travel} />
                {/* <Route exact path='/things' component={Things} />
                <Route exact path='/faqs' component={Faqs} /> */}
                <Route exact path='/registry' component={Registry} />
                <Route render={() => ('Not Found 404')} />
            </Switch>
        </Fragment>
    );
}

export default Navigation;