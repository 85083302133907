import React, { Fragment, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';


import LILE_WHILE from '../../images/line-nav.png';
import MONOGRAMA from '../../images/shedule-monograma.png';
import './menu.css';

const Menu = () => {
    const [borderHome, setBorderHome] = useState('');
    const [borderShedule, setBorderShedule] = useState('');
    const [borderTravel, setBorderTravel] = useState('');
    const [borderToDo, setBorderToDo] = useState('');
    const [borderFaqs, setBorderFaqs] = useState('');
    const [borderRegistry, setBorderRegistry] = useState('');
    const [expanded, setExpanded] = useState(false);


    let pat = useLocation();

    useEffect(() => {
        if (pat.pathname === '/') {
            setBorderHome('nav-borders')
            setBorderShedule('')
            setBorderTravel('')
            setBorderToDo('')
            setBorderFaqs('')
            setBorderRegistry('')

        } else if (pat.pathname === '/schedule') {
            setBorderHome('')
            setBorderShedule('nav-borders')
            setBorderTravel('')
            setBorderToDo('')
            setBorderFaqs('')
            setBorderRegistry('')

        } else if (pat.pathname === '/travel') {
            setBorderHome('')
            setBorderShedule('')
            setBorderTravel('nav-borders')
            setBorderToDo('')
            setBorderFaqs('')
            setBorderRegistry('')

        } else if (pat.pathname === '/things') {
            setBorderHome('')
            setBorderShedule('')
            setBorderTravel('')
            setBorderToDo('nav-borders')
            setBorderFaqs('')
            setBorderRegistry('')

        } else if (pat.pathname === '/faqs') {
            setBorderHome('')
            setBorderShedule('')
            setBorderTravel('')
            setBorderToDo('')
            setBorderFaqs('nav-borders')
            setBorderRegistry('')

        } else if (pat.pathname === '/registry') {
            setBorderHome('')
            setBorderShedule('')
            setBorderTravel('')
            setBorderToDo('')
            setBorderFaqs('')
            setBorderRegistry('nav-borders')
        }


    }, [pat])

    return (
        <Fragment>
            {/* Menu Grande */}
            <nav className="navbar navbar-expand-md nav-large d-none d-md-block">
                <div className="navbar-collapse navbar-large d-flex align-items-center justify-content-between" id="navbarNav">
                    <div className="home-date">
                        <div className="date-uno">
                            <p>NOVEMBER 28, SOUTH CAICOS, TURKS AND CAICOS ISLANDS</p>
                        </div>
                        <div className="date-dos">
                            <p>#KERVELSTIPA</p>
                        </div>
                    </div>

                    <div className="home-elisa">
                        <p>
                            ELISA AND DANIEL´S WEDDING
                        </p>
                    </div>
                    <div className="home-img">
                        <img src={LILE_WHILE} alt="" />
                    </div>
                    <ul className="">
                        <li className="nav-item mx-md-2 mx-lg-4">
                            <Link to="/" className={"nav-link " + borderHome}>
                                HOME
                            </Link>
                        </li>
                        <li className="nav-item mx-md-2 mx-lg-4">
                            <Link to="/schedule" className={"nav-link " + borderShedule}>
                                SCHEDULE
                            </Link>
                        </li>
                        <li className="nav-item mx-md-2 mx-lg-4">
                            <Link to="/travel" className={"nav-link " + borderTravel}>
                                TRAVEL
                            </Link>
                        </li>
                        {/* <li className="nav-item mx-md-2 mx-lg-4">
                            <Link to="/things" className={"nav-link " + borderToDo}>
                                THINGS TO DO
                            </Link>
                        </li> */}
                        {/* <li className="nav-item mx-md-2 mx-lg-4">
                            <Link to="/faqs" className={"nav-link " + borderFaqs}>
                                FAQS
                            </Link>
                        </li> */}
                        <li className="nav-item mx-md-2 mx-lg-4">
                            <Link to="/registry" className={"nav-link " + borderRegistry}>
                                REGISTRY
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
            {/* *************************************************** */}
            {/* pequeña */}

            <Navbar expanded={expanded} expand="md" className="d-md-none nav-small">
                <Navbar.Brand >
                    <Link to="/" className="brand-elisa">ELISA AND  <br />DANIEL´S <br /> WEDDING</Link>
                </Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} >
                    <div className="img-monograma">
                        <img src={MONOGRAMA} alt="" />
                    </div>
                </Navbar.Toggle>

                <Navbar.Collapse id="basic-navbar-nav" className="">
                    <Nav className="mr-auto navbar-small">
                        <ul className="navbar-nav text-center">
                            <li className="nav-item">
                                <Link to="/" onClick={() => setExpanded(false)} className={"nav-link " + borderHome}>
                                    HOME
                            </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/schedule" onClick={() => setExpanded(false)} className={"nav-link " + borderShedule}>
                                    SCHEDULE
                            </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/travel" onClick={() => setExpanded(false)} className={"nav-link " + borderTravel}>
                                    TRAVEL
                            </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to="/things" onClick={() => setExpanded(false)} className={"nav-link " + borderToDo}>
                                    THINGS TO DO
                            </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/faqs" onClick={() => setExpanded(false)} className={"nav-link " + borderFaqs}>
                                    FAQS
                            </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link to="/registry" onClick={() => setExpanded(false)} className={"nav-link " + borderRegistry}>
                                    REGISTRY
                            </Link>
                            </li>
                        </ul>

                    </Nav>
                </Navbar.Collapse>
            </Navbar>


        </Fragment>
    );
};

export default Menu;