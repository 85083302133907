import React, { Fragment } from 'react';
import './registry.css';

import TextParagraph from '../../components/descriptions/TextParagraph';
import ButtonPeper from '../../components/descriptions/Boton';
import GoHere from '../../components/descriptions/GoHere';

const Registry = () => {
    return (
        <Fragment>
            <div className="registry-aside d-none d-md-block">

            </div>
            <div className="registry-plant-right d-none d-md-block">

            </div>
            {/* Design mobile */}
            <div className="d-md-none faqs-text">
                <div className="main-mobile-description">

                    <TextParagraph
                        estilo='description-mobile-title'
                        descriptions='ZOLA:'
                    />
                    <TextParagraph
                        estilo='text-mobile-description'
                        descriptions='Elisa and Daniel'
                    />
                    <ButtonPeper
                        estiloButton='main-button-mobile-zola'
                        textValue='Click here'
                        LinkGo='https://www.zola.com/registry/elisa-daniel'

                    />
                    {/* <div className="">
                   
                        <TextParagraph
                            estilo='description-mobile-title'
                            descriptions='MAISON 77:'
                        />

                        <GoHere
                            estilo='text-mobile-description'
                            descriptions='The Ritz-Carlton, Aruba:'
                            toGo='https://www.instagram.com/maison.77/?hl=es'
                            textToGo='Go here'
                        />

                        <TextParagraph
                            estilo='description-mobile-title mt-4'
                            descriptions="II'ARGENTO:"
                        />

                        <GoHere
                            estilo='text-mobile-description'
                            descriptions='Caracas'
                            toGo='https://ilargento.com/'
                            textToGo='Go here'
                        />

                        <TextParagraph
                            estilo='description-mobile-title mt-4'
                            descriptions='ZOLA.COM:'
                        />

                        <GoHere
                            estilo='text-mobile-description'
                            descriptions="Elisa & Daniel's"
                            toGo='http://www.zola.com/registry/elisa-daniel/'
                            textToGo='Go here'
                        />

                        <ButtonPeper
                            estiloButton='main-button-mobile-zola mt-4'

                            textValue='Go To Paperless Post'
                            LinkGo='https://www.paperlesspost.com/'
                        />
                    </div> */}
                </div>
            </div>
            {/* End design mobile */}


            <div className="shedule d-none d-md-block">
                <TextParagraph
                    estilo='description-text-title'
                    descriptions='ZOLA:'
                />
                <TextParagraph
                    estilo='text-description-shedule mt-2'
                    descriptions='Elisa and Daniel'
                />
                <ButtonPeper
                    estiloButton='main-button-zola'
                    textValue='Click here'
                    LinkGo='https://www.zola.com/registry/elisa-daniel'

                />
                {/* <div className="">
                    <TextParagraph
                        estilo='description-text-title'
                        descriptions='MAISON 77:'
                    />

                    <GoHere
                        estilo='text-description'
                        descriptions='The Ritz-Carlton, Aruba:'
                        toGo='https://www.instagram.com/maison.77/?hl=es'
                        textToGo='Go here'
                    />

                    <TextParagraph
                        estilo='description-text-title mt-4'
                        descriptions="II'ARGENTO:"
                    />

                    <GoHere
                        estilo='text-description'
                        descriptions='Caracas'
                        toGo='https://ilargento.com/'
                        textToGo='Go here'
                    />

                    <TextParagraph
                        estilo='description-text-title mt-4'
                        descriptions='ZOLA.COM:'
                    />

                    <GoHere
                        estilo='text-description'
                        descriptions="Elisa & Daniel's"
                        toGo='http://www.zola.com/registry/elisa-daniel/'
                        textToGo='Go here'
                    />


                    <ButtonPeper
                        estiloButton='main-button-zola'

                        textValue='Go To Paperless Post'
                        LinkGo='https://www.paperlesspost.com/'

                    />
                </div> */}
            </div>
        </Fragment>
    );
};

export default Registry;